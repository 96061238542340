import React from 'react';
import './App.css'; // Importing CSS file

class App extends React.Component {
  render() {
    return (
      <div className="welcome-container">
        <h1 className="welcome-heading">Welcome to Jungle Chip</h1>
        <p className="welcome-text">Acclerate your business</p>
      </div>
    );
  }
}

export default App;
